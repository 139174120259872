<template>
  <div v-if="(!ka)">Loading Please wait...
  </div>
  <div v-else>
  <div id="app">
    <!-- <ejs-schedule id='Schedule' ref='ScheduleObj' :locale='locale' :height='height' :selectedDate='selectedDate' :eventSettings='eventSettings' :popupOpen='onPopupOpen' :actionBegin='onActionBegin' :created='onCreate' :currentView='currentView'></ejs-schedule> -->
    <ejs-schedule id='Schedule' ref='ScheduleObj' :locale='locale' :height='height' :selectedDate='selectedDate' :eventSettings='eventSettings' :actionBegin='onActionBegin' :currentView='currentView'></ejs-schedule>
  </div>
  </div>
</template>

<script>
import Vue from 'vue'
import {
  SchedulePlugin, Day, Week, WorkWeek, Month, Agenda, Resize, DragAndDrop,
} from '@syncfusion/ej2-vue-schedule'
import { L10n, loadCldr, setCulture } from '@syncfusion/ej2-base'
// import { ContextMenuPlugin } from '@syncfusion/ej2-vue-navigations'

import kal from '@/service/kalender'

import * as numberingSystems from '../../../node_modules/cldr-data/supplemental/numberingSystems.json'
import * as gregorian from '../../../node_modules/cldr-data/main/de-CH/ca-gregorian.json'
import * as numbers from '../../../node_modules/cldr-data/main/de-CH/numbers.json'
import * as timeZoneNames from '../../../node_modules/cldr-data/main/de-CH/timeZoneNames.json'
import * as gregorianfr from '../../../node_modules/cldr-data/main/fr-CH/ca-gregorian.json'
import * as numbersfr from '../../../node_modules/cldr-data/main/fr-CH/numbers.json'
import * as timeZoneNamesfr from '../../../node_modules/cldr-data/main/fr-CH/timeZoneNames.json'

import localeDeutsch from '../../assets/syncfusion-locales/sched-de.json'
import localeFrancais from '../../assets/syncfusion-locales/sched-fr.json'

Vue.use(SchedulePlugin)
// Vue.use(ContextMenuPlugin)

export default {
  data() {
    return {
      ka: null,
      height: window.innerHeight - 145,
      locale: 'de-CH',
      selectedDate: new Date(),
      // currentView: 'Week',
      currentView: 'Agenda',
      eventSettings: {
        dataSource: this.ka,
      },
    }
  },
  methods: {
    // onPopupOpen(args) { // set event to get save action from  details editor...
    // onPopupOpen() { // set event to get save action from  details editor...
    //   // const scheduleObj = this.$refs.ScheduleObj.ej2Instances
    //   // console.log(args)
    //   // if (args.type === 'Editor') {
    //   //   const eventSave = args.element.querySelector('.e-event-save')
    //   //   if (eventSave) {
    //   //     eventSave.addEventListener('click', this.onEventSave(args))
    //   //   }
    //   // }
    // },
    // onCreate() {
    //   // this.currentView = 'Week'
    //   this.$refs.ScheduleObj.ej2Instances.refresh()
    //   this.eventSettings.dataSource = this.ka
    // },
    // onEventSave() {
    // // onEventSave(args) {
    //   // console.log('onEventSave...')
    //   // console.log(this.$refs.ScheduleObj.ej2Instances.getEvents())
    //   // console.log(args.data)
    //   // It will be triggered when we press Save button of the Editor Window
    //   // And also we can save the Data
    // },
    onActionBegin(args) {
      // eventCreate has array in args.data, eventChange and eventRemove have one record in data...
      if (args.requestType === 'eventCreate' || args.requestType === 'eventChange') {
        let r = {}
        if (args.requestType === 'eventCreate') r = { ...args.data[0] }
        else r = args.data

        r.Subject = (args.requestType === 'eventCreate' ? (this.$root.currDosBez + ': ') : '') + r.Subject

        this.ka[0].DosID = this.$root.currDosID
        this.ka[0].Id = r.Id
        this.ka[0].Subject = r.Subject
        this.ka[0].Descripiton = r.Descripiton
        this.ka[0].StartTime = r.StartTime
        this.ka[0].StartTimezone = r.StartTimezone ?? ''
        this.ka[0].EndTime = r.EndTime
        this.ka[0].EndTimezone = r.EndTimezone ?? ''
        this.ka[0].Location = r.Location
        this.ka[0].IsAllDay = r.IsAllDay ?? 0
        this.ka[0].IsReadOnly = r.IsReadOnly ?? 0
        this.ka[0].IsBlock = r.IsBlock ?? 0
        kal.putKalender(this.ka[0])
      }

      if (args.requestType === 'eventRemove') kal.delKalender(args.data[0].Id)
    },
  },
  provide: {
    schedule: [Day, Week, WorkWeek, Month, Agenda, Resize, DragAndDrop],
  },
  async created() {
    loadCldr(numberingSystems, this.$g.user.Sprache === 'fr' ? gregorianfr : gregorian, this.$g.user.Sprache === 'fr' ? numbersfr : numbers, this.$g.user.Sprache === 'fr' ? timeZoneNamesfr : timeZoneNames)
    L10n.load(this.$g.user.Sprache === 'fr' ? localeFrancais : localeDeutsch)
    setCulture(this.$g.user.Sprache === 'fr' ? 'fr-CH' : 'de-CH')

    const rk = await kal.getKalender(this.$root.currDosID, this.$g.user.BenID)
    if (rk.data.length > 0) this.ka = rk.data
    this.eventSettings.dataSource = this.ka

    await this.$nextTick()
    this.$refs.ScheduleObj.ej2Instances.refreshLayout()
    // this.$refs.ScheduleObj.ej2Instances.dataBind()
    // this.$refs.ScheduleObj.ej2Instances.refresh()
  },
}

</script>
<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
@import '../../../node_modules/@syncfusion/ej2-base/styles/bootstrap5.css';
@import '../../../node_modules/@syncfusion/ej2-buttons/styles/bootstrap5.css';
@import '../../../node_modules/@syncfusion/ej2-calendars/styles/bootstrap5.css';
@import '../../../node_modules/@syncfusion/ej2-dropdowns/styles/bootstrap5.css';
@import '../../../node_modules/@syncfusion/ej2-inputs/styles/bootstrap5.css';
@import '../../../node_modules/@syncfusion/ej2-navigations/styles/bootstrap5.css';
@import '../../../node_modules/@syncfusion/ej2-popups/styles/bootstrap5.css';
@import '../../../node_modules/@syncfusion/ej2-schedule/styles/bootstrap5.css';
</style>
<!--
<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
@import '../../../node_modules/@syncfusion/ej2-base/styles/bootstrap5.css';
@import '../../../node_modules/@syncfusion/ej2-buttons/styles/bootstrap5.css';
@import '../../../node_modules/@syncfusion/ej2-calendars/styles/bootstrap5.css';
@import '../../../node_modules/@syncfusion/ej2-dropdowns/styles/bootstrap5.css';
@import '../../../node_modules/@syncfusion/ej2-inputs/styles/bootstrap5.css';
@import '../../../node_modules/@syncfusion/ej2-navigations/styles/bootstrap5.css';
@import '../../../node_modules/@syncfusion/ej2-popups/styles/bootstrap5.css';
@import '../../../node_modules/@syncfusion/ej2-vue-schedule/styles/bootstrap5.css';
</style> -->
